var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticClass:"pd-b-3"},[_c('el-col',{attrs:{"xs":{ span: 24, offset: 0 },"sm":_vm.$route.name == 'Factor'
        ? { span: 14, offset: 5 }
        : { span: 18, offset: 3 },"lg":_vm.$route.name == 'Factor'
        ? { span: 10, offset: 7 }
        : { span: 18, offset: 3 },"xl":_vm.$route.name == 'Factor'
        ? { span: 8, offset: 8 }
        : { span: 12, offset: 6 }}},[_c('div',{staticClass:"padding-grid"},[_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }